@import-normalize; /* bring in normalize.css styles */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modalClose {
  position: absolute;
}

.burger {
  width: 32px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.burger--active .burger__line:nth-of-type(1) {
  transform: rotate(45deg) translate(8px, 4px);
  width: 100%;
}
.burger--active .burger__line:nth-of-type(2) {
  transform: rotate(-45deg) translate(3px, 1px);
}
.burger--active .burger__line:nth-of-type(3) {
  display: none;
}
.popup--animated .burger__line {
  width: 100%;
  height: 3px;
  margin-bottom: 7px;
  background-color: rgba(0, 0, 0, 0.8);
}
.burger__line {
  width: 100%;
  height: 3px;
  margin-bottom: 7px;
  background-color: rgba(0, 0, 0, 0.8);
}

.burger .burger__line:nth-of-type(3) {
  margin-bottom: 0;
}