
.ConfigsListContent {
  display: flex;
  flex-wrap: wrap;
}
.ConfigsList {
  flex: 1 1 25%;
  /* margin-right: 20px; */
}
.configItem {
  padding: 1vh 1vw;
  cursor: pointer;
  border: 1px solid #1976d200;
  border-radius: 5px;
}
.configItem.selected {
  border: 1px solid #1976d2;
  border-radius: 5px;
}
.ConfigW {
  flex: 1 1 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ConfigContent {
  flex: 1;
  display: flex;
}
.configQRCode {
  width: 256px;
  height: 256px;
}
.configFmt {
  resize: none;
  padding: 1vh 1vw;
  font-size: 1.4vmin;
  outline: none!important;
  /* border: 1px solid #4295cf99;
  border-radius: 6px; */
}

.ConfigsListButtons {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: space-evenly;
  margin-bottom: 1vh;
}

/* .actionButton {
  outline: none!important;
  touch-action: manipulation;
  border: 2px solid #4295cf99;
  padding: 4px 12px;
  border-radius: 6px;
  color: #4095ce;
  margin: 8px 0;
}
.actionButton:hover {
  background: #4295cfd9;
  transition: background 300ms ease;
  background-color: #4095ce;
  color: #fff;
} */

.CreateConfig {
  width: 100%;
}
.CreateConfig .serversList {
  max-height: 20vh;
}

